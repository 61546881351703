import React from 'react';
import LocalizedLink from '../locale/LocalizedLink';
import makeButton from './hoc/makeButton';

export const Button = makeButton(({ children, ...rest }) => <button {...rest}>{children}</button>);

export const ButtonAnchor = makeButton(({ children, ...rest }) => <a {...rest}>{children}</a>);

export const ButtonSpan = makeButton(({ children, ...rest }) => <span {...rest}>{children}</span>);

export const ButtonLink = makeButton(LocalizedLink);
