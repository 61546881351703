const breakpoint = {
  tiny: '361px',
  xxs: '400px',
  xs: '481px',
  sm: '769px',
  md: '993px',
  lg: '1201px',
  xl: '1441px',
  xxl: '1901px',
  mobileNav: '910px'
};

export default breakpoint;
