import { css } from '@emotion/core';
import breakpoint from './breakpoint';

const spacing = {
  grid: '30px',
  tiny: '0.8rem',
  xxs: '1.6rem',
  xs: '2.4rem',
  sm: '3.2rem',
  md: '4.8rem',
  lg: '7.2rem',
  xl: '9.6rem',
  xxl: '12.8rem'
};

export default spacing;

export const spacingHelpers = css`
  /*----------  Margin Top  ----------*/

  /* grid */
  .h-mt-grid {
    margin-top: ${spacing.grid} !important;
  }
  .h-mt-grid--xs {
    @media (min-width: ${breakpoint.xs}) {
      margin-top: ${spacing.grid} !important;
    }
  }
  .h-mt-grid--sm {
    @media (min-width: ${breakpoint.sm}) {
      margin-top: ${spacing.grid} !important;
    }
  }
  .h-mt-grid--md {
    @media (min-width: ${breakpoint.md}) {
      margin-top: ${spacing.grid} !important;
    }
  }
  .h-mt-grid--lg {
    @media (min-width: ${breakpoint.lg}) {
      margin-top: ${spacing.grid} !important;
    }
  }
  .h-mt-grid--xl {
    @media (min-width: ${breakpoint.xl}) {
      margin-top: ${spacing.grid} !important;
    }
  }

  /* tiny */
  .h-mt-tiny {
    margin-top: ${spacing.tiny} !important;
  }
  .h-mt-tiny--xs {
    @media (min-width: ${breakpoint.xs}) {
      margin-top: ${spacing.tiny} !important;
    }
  }
  .h-mt-tiny--sm {
    @media (min-width: ${breakpoint.sm}) {
      margin-top: ${spacing.tiny} !important;
    }
  }
  .h-mt-tiny--md {
    @media (min-width: ${breakpoint.md}) {
      margin-top: ${spacing.tiny} !important;
    }
  }
  .h-mt-tiny--lg {
    @media (min-width: ${breakpoint.lg}) {
      margin-top: ${spacing.tiny} !important;
    }
  }
  .h-mt-tiny--xl {
    @media (min-width: ${breakpoint.xl}) {
      margin-top: ${spacing.tiny} !important;
    }
  }

  /* xxs */
  .h-mt-xxs {
    margin-top: ${spacing.xxs} !important;
  }
  .h-mt-xxs--xs {
    @media (min-width: ${breakpoint.xs}) {
      margin-top: ${spacing.xxs} !important;
    }
  }
  .h-mt-xxs--sm {
    @media (min-width: ${breakpoint.sm}) {
      margin-top: ${spacing.xxs} !important;
    }
  }
  .h-mt-xxs--md {
    @media (min-width: ${breakpoint.md}) {
      margin-top: ${spacing.xxs} !important;
    }
  }
  .h-mt-xxs--lg {
    @media (min-width: ${breakpoint.lg}) {
      margin-top: ${spacing.xxs} !important;
    }
  }
  .h-mt-xxs--xl {
    @media (min-width: ${breakpoint.xl}) {
      margin-top: ${spacing.xxs} !important;
    }
  }

  /* xs */
  .h-mt-xs {
    margin-top: ${spacing.xs} !important;
  }
  .h-mt-xs--xs {
    @media (min-width: ${breakpoint.xs}) {
      margin-top: ${spacing.xs} !important;
    }
  }
  .h-mt-xs--sm {
    @media (min-width: ${breakpoint.sm}) {
      margin-top: ${spacing.xs} !important;
    }
  }
  .h-mt-xs--md {
    @media (min-width: ${breakpoint.md}) {
      margin-top: ${spacing.xs} !important;
    }
  }
  .h-mt-xs--lg {
    @media (min-width: ${breakpoint.lg}) {
      margin-top: ${spacing.xs} !important;
    }
  }
  .h-mt-xs--xl {
    @media (min-width: ${breakpoint.xl}) {
      margin-top: ${spacing.xs} !important;
    }
  }

  /* sm */
  .h-mt-sm {
    margin-top: ${spacing.sm} !important;
  }
  .h-mt-sm--xs {
    @media (min-width: ${breakpoint.xs}) {
      margin-top: ${spacing.sm} !important;
    }
  }
  .h-mt-sm--sm {
    @media (min-width: ${breakpoint.sm}) {
      margin-top: ${spacing.sm} !important;
    }
  }
  .h-mt-sm--md {
    @media (min-width: ${breakpoint.md}) {
      margin-top: ${spacing.sm} !important;
    }
  }
  .h-mt-sm--lg {
    @media (min-width: ${breakpoint.lg}) {
      margin-top: ${spacing.sm} !important;
    }
  }
  .h-mt-sm--xl {
    @media (min-width: ${breakpoint.xl}) {
      margin-top: ${spacing.sm} !important;
    }
  }

  /* md */
  .h-mt-md {
    margin-top: ${spacing.md} !important;
  }
  .h-mt-md--xs {
    @media (min-width: ${breakpoint.xs}) {
      margin-top: ${spacing.md} !important;
    }
  }
  .h-mt-md--sm {
    @media (min-width: ${breakpoint.sm}) {
      margin-top: ${spacing.md} !important;
    }
  }
  .h-mt-md--md {
    @media (min-width: ${breakpoint.md}) {
      margin-top: ${spacing.md} !important;
    }
  }
  .h-mt-md--lg {
    @media (min-width: ${breakpoint.lg}) {
      margin-top: ${spacing.md} !important;
    }
  }
  .h-mt-md--xl {
    @media (min-width: ${breakpoint.xl}) {
      margin-top: ${spacing.md} !important;
    }
  }

  /* lg */
  .h-mt-lg {
    margin-top: ${spacing.lg} !important;
  }
  .h-mt-lg--xs {
    @media (min-width: ${breakpoint.xs}) {
      margin-top: ${spacing.lg} !important;
    }
  }
  .h-mt-lg--sm {
    @media (min-width: ${breakpoint.sm}) {
      margin-top: ${spacing.lg} !important;
    }
  }
  .h-mt-lg--md {
    @media (min-width: ${breakpoint.md}) {
      margin-top: ${spacing.lg} !important;
    }
  }
  .h-mt-lg--lg {
    @media (min-width: ${breakpoint.lg}) {
      margin-top: ${spacing.lg} !important;
    }
  }
  .h-mt-lg--xl {
    @media (min-width: ${breakpoint.xl}) {
      margin-top: ${spacing.lg} !important;
    }
  }

  /* xl */
  .h-mt-xl {
    margin-top: ${spacing.xl} !important;
  }
  .h-mt-xl--xs {
    @media (min-width: ${breakpoint.xs}) {
      margin-top: ${spacing.xl} !important;
    }
  }
  .h-mt-xl--sm {
    @media (min-width: ${breakpoint.sm}) {
      margin-top: ${spacing.xl} !important;
    }
  }
  .h-mt-xl--md {
    @media (min-width: ${breakpoint.md}) {
      margin-top: ${spacing.xl} !important;
    }
  }
  .h-mt-xl--lg {
    @media (min-width: ${breakpoint.lg}) {
      margin-top: ${spacing.xl} !important;
    }
  }
  .h-mt-xl--xl {
    @media (min-width: ${breakpoint.xl}) {
      margin-top: ${spacing.xl} !important;
    }
  }

  /* xxl */
  .h-mt-xxl {
    margin-top: ${spacing.xxl} !important;
  }
  .h-mt-xxl--xs {
    @media (min-width: ${breakpoint.xs}) {
      margin-top: ${spacing.xxl} !important;
    }
  }
  .h-mt-xxl--sm {
    @media (min-width: ${breakpoint.sm}) {
      margin-top: ${spacing.xxl} !important;
    }
  }
  .h-mt-xxl--md {
    @media (min-width: ${breakpoint.md}) {
      margin-top: ${spacing.xxl} !important;
    }
  }
  .h-mt-xxl--lg {
    @media (min-width: ${breakpoint.lg}) {
      margin-top: ${spacing.xxl} !important;
    }
  }
  .h-mt-xxl--xl {
    @media (min-width: ${breakpoint.xl}) {
      margin-top: ${spacing.xxl} !important;
    }
  }

  /* None */
  .h-mt-none {
    margin-top: 0 !important;
  }
  .h-mt-none--xs {
    @media (min-width: ${breakpoint.xs}) {
      margin-top: 0 !important;
    }
  }
  .h-mt-none--sm {
    @media (min-width: ${breakpoint.sm}) {
      margin-top: 0 !important;
    }
  }
  .h-mt-none--md {
    @media (min-width: ${breakpoint.md}) {
      margin-top: 0 !important;
    }
  }
  .h-mt-none--lg {
    @media (min-width: ${breakpoint.lg}) {
      margin-top: 0 !important;
    }
  }
  .h-mt-none--xl {
    @media (min-width: ${breakpoint.xl}) {
      margin-top: 0 !important;
    }
  }

  /*----------  Margin Bottom  ----------*/

  /* grid */
  .h-mb-grid {
    margin-bottom: ${spacing.grid} !important;
  }
  .h-mb-grid--xs {
    @media (min-width: ${breakpoint.xs}) {
      margin-bottom: ${spacing.grid} !important;
    }
  }
  .h-mb-grid--sm {
    @media (min-width: ${breakpoint.sm}) {
      margin-bottom: ${spacing.grid} !important;
    }
  }
  .h-mb-grid--md {
    @media (min-width: ${breakpoint.md}) {
      margin-bottom: ${spacing.grid} !important;
    }
  }
  .h-mb-grid--lg {
    @media (min-width: ${breakpoint.lg}) {
      margin-bottom: ${spacing.grid} !important;
    }
  }
  .h-mb-grid--xl {
    @media (min-width: ${breakpoint.xl}) {
      margin-bottom: ${spacing.grid} !important;
    }
  }

  /* tiny */
  .h-mb-tiny {
    margin-bottom: ${spacing.tiny} !important;
  }
  .h-mb-tiny--xs {
    @media (min-width: ${breakpoint.xs}) {
      margin-bottom: ${spacing.tiny} !important;
    }
  }
  .h-mb-tiny--sm {
    @media (min-width: ${breakpoint.sm}) {
      margin-bottom: ${spacing.tiny} !important;
    }
  }
  .h-mb-tiny--md {
    @media (min-width: ${breakpoint.md}) {
      margin-bottom: ${spacing.tiny} !important;
    }
  }
  .h-mb-tiny--lg {
    @media (min-width: ${breakpoint.lg}) {
      margin-bottom: ${spacing.tiny} !important;
    }
  }
  .h-mb-tiny--xl {
    @media (min-width: ${breakpoint.xl}) {
      margin-bottom: ${spacing.tiny} !important;
    }
  }

  /* xxs */
  .h-mb-xxs {
    margin-bottom: ${spacing.xxs} !important;
  }
  .h-mb-xxs--xs {
    @media (min-width: ${breakpoint.xs}) {
      margin-bottom: ${spacing.xxs} !important;
    }
  }
  .h-mb-xxs--sm {
    @media (min-width: ${breakpoint.sm}) {
      margin-bottom: ${spacing.xxs} !important;
    }
  }
  .h-mb-xxs--md {
    @media (min-width: ${breakpoint.md}) {
      margin-bottom: ${spacing.xxs} !important;
    }
  }
  .h-mb-xxs--lg {
    @media (min-width: ${breakpoint.lg}) {
      margin-bottom: ${spacing.xxs} !important;
    }
  }
  .h-mb-xxs--xl {
    @media (min-width: ${breakpoint.xl}) {
      margin-bottom: ${spacing.xxs} !important;
    }
  }

  /* xs */
  .h-mb-xs {
    margin-bottom: ${spacing.xs} !important;
  }
  .h-mb-xs--xs {
    @media (min-width: ${breakpoint.xs}) {
      margin-bottom: ${spacing.xs} !important;
    }
  }
  .h-mb-xs--sm {
    @media (min-width: ${breakpoint.sm}) {
      margin-bottom: ${spacing.xs} !important;
    }
  }
  .h-mb-xs--md {
    @media (min-width: ${breakpoint.md}) {
      margin-bottom: ${spacing.xs} !important;
    }
  }
  .h-mb-xs--lg {
    @media (min-width: ${breakpoint.lg}) {
      margin-bottom: ${spacing.xs} !important;
    }
  }
  .h-mb-xs--xl {
    @media (min-width: ${breakpoint.xl}) {
      margin-bottom: ${spacing.xs} !important;
    }
  }

  /* sm */
  .h-mb-sm {
    margin-bottom: ${spacing.sm} !important;
  }
  .h-mb-sm--xs {
    @media (min-width: ${breakpoint.xs}) {
      margin-bottom: ${spacing.sm} !important;
    }
  }
  .h-mb-sm--sm {
    @media (min-width: ${breakpoint.sm}) {
      margin-bottom: ${spacing.sm} !important;
    }
  }
  .h-mb-sm--md {
    @media (min-width: ${breakpoint.md}) {
      margin-bottom: ${spacing.sm} !important;
    }
  }
  .h-mb-sm--lg {
    @media (min-width: ${breakpoint.lg}) {
      margin-bottom: ${spacing.sm} !important;
    }
  }
  .h-mb-sm--xl {
    @media (min-width: ${breakpoint.xl}) {
      margin-bottom: ${spacing.sm} !important;
    }
  }

  /* md */
  .h-mb-md {
    margin-bottom: ${spacing.md} !important;
  }
  .h-mb-md--xs {
    @media (min-width: ${breakpoint.xs}) {
      margin-bottom: ${spacing.md} !important;
    }
  }
  .h-mb-md--sm {
    @media (min-width: ${breakpoint.sm}) {
      margin-bottom: ${spacing.md} !important;
    }
  }
  .h-mb-md--md {
    @media (min-width: ${breakpoint.md}) {
      margin-bottom: ${spacing.md} !important;
    }
  }
  .h-mb-md--lg {
    @media (min-width: ${breakpoint.lg}) {
      margin-bottom: ${spacing.md} !important;
    }
  }
  .h-mb-md--xl {
    @media (min-width: ${breakpoint.xl}) {
      margin-bottom: ${spacing.md} !important;
    }
  }

  /* lg */
  .h-mb-lg {
    margin-bottom: ${spacing.lg} !important;
  }
  .h-mb-lg--xs {
    @media (min-width: ${breakpoint.xs}) {
      margin-bottom: ${spacing.lg} !important;
    }
  }
  .h-mb-lg--sm {
    @media (min-width: ${breakpoint.sm}) {
      margin-bottom: ${spacing.lg} !important;
    }
  }
  .h-mb-lg--md {
    @media (min-width: ${breakpoint.md}) {
      margin-bottom: ${spacing.lg} !important;
    }
  }
  .h-mb-lg--lg {
    @media (min-width: ${breakpoint.lg}) {
      margin-bottom: ${spacing.lg} !important;
    }
  }
  .h-mb-lg--xl {
    @media (min-width: ${breakpoint.xl}) {
      margin-bottom: ${spacing.lg} !important;
    }
  }

  /* xl */
  .h-mb-xl {
    margin-bottom: ${spacing.xl} !important;
  }
  .h-mb-xl--xs {
    @media (min-width: ${breakpoint.xs}) {
      margin-bottom: ${spacing.xl} !important;
    }
  }
  .h-mb-xl--sm {
    @media (min-width: ${breakpoint.sm}) {
      margin-bottom: ${spacing.xl} !important;
    }
  }
  .h-mb-xl--md {
    @media (min-width: ${breakpoint.md}) {
      margin-bottom: ${spacing.xl} !important;
    }
  }
  .h-mb-xl--lg {
    @media (min-width: ${breakpoint.lg}) {
      margin-bottom: ${spacing.xl} !important;
    }
  }
  .h-mb-xl--xl {
    @media (min-width: ${breakpoint.xl}) {
      margin-bottom: ${spacing.xl} !important;
    }
  }

  /* xxl */
  .h-mb-xxl {
    margin-bottom: ${spacing.xxl} !important;
  }
  .h-mb-xxl--xs {
    @media (min-width: ${breakpoint.xs}) {
      margin-bottom: ${spacing.xxl} !important;
    }
  }
  .h-mb-xxl--sm {
    @media (min-width: ${breakpoint.sm}) {
      margin-bottom: ${spacing.xxl} !important;
    }
  }
  .h-mb-xxl--md {
    @media (min-width: ${breakpoint.md}) {
      margin-bottom: ${spacing.xxl} !important;
    }
  }
  .h-mb-xxl--lg {
    @media (min-width: ${breakpoint.lg}) {
      margin-bottom: ${spacing.xxl} !important;
    }
  }
  .h-mb-xxl--xl {
    @media (min-width: ${breakpoint.xl}) {
      margin-bottom: ${spacing.xxl} !important;
    }
  }

  /* None */
  .h-mb-none {
    margin-bottom: 0 !important;
  }
  .h-mb-none--xs {
    @media (min-width: ${breakpoint.xs}) {
      margin-bottom: 0 !important;
    }
  }
  .h-mb-none--sm {
    @media (min-width: ${breakpoint.sm}) {
      margin-bottom: 0 !important;
    }
  }
  .h-mb-none--md {
    @media (min-width: ${breakpoint.md}) {
      margin-bottom: 0 !important;
    }
  }
  .h-mb-none--lg {
    @media (min-width: ${breakpoint.lg}) {
      margin-bottom: 0 !important;
    }
  }
  .h-mb-none--xl {
    @media (min-width: ${breakpoint.xl}) {
      margin-bottom: 0 !important;
    }
  }

  /*----------  Padding Top  ----------*/

  /* tiny */
  .h-pt-tiny {
    padding-top: ${spacing.tiny} !important;
  }
  .h-pt-tiny--xs {
    @media (min-width: ${breakpoint.xs}) {
      padding-top: ${spacing.tiny} !important;
    }
  }
  .h-pt-tiny--sm {
    @media (min-width: ${breakpoint.sm}) {
      padding-top: ${spacing.tiny} !important;
    }
  }
  .h-pt-tiny--md {
    @media (min-width: ${breakpoint.md}) {
      padding-top: ${spacing.tiny} !important;
    }
  }
  .h-pt-tiny--lg {
    @media (min-width: ${breakpoint.lg}) {
      padding-top: ${spacing.tiny} !important;
    }
  }
  .h-pt-tiny--xl {
    @media (min-width: ${breakpoint.xl}) {
      padding-top: ${spacing.tiny} !important;
    }
  }

  /* xxs */
  .h-pt-xxs {
    padding-top: ${spacing.xxs} !important;
  }
  .h-pt-xxs--xs {
    @media (min-width: ${breakpoint.xs}) {
      padding-top: ${spacing.xxs} !important;
    }
  }
  .h-pt-xxs--sm {
    @media (min-width: ${breakpoint.sm}) {
      padding-top: ${spacing.xxs} !important;
    }
  }
  .h-pt-xxs--md {
    @media (min-width: ${breakpoint.md}) {
      padding-top: ${spacing.xxs} !important;
    }
  }
  .h-pt-xxs--lg {
    @media (min-width: ${breakpoint.lg}) {
      padding-top: ${spacing.xxs} !important;
    }
  }
  .h-pt-xxs--xl {
    @media (min-width: ${breakpoint.xl}) {
      padding-top: ${spacing.xxs} !important;
    }
  }

  /* xs */
  .h-pt-xs {
    padding-top: ${spacing.xs} !important;
  }
  .h-pt-xs--xs {
    @media (min-width: ${breakpoint.xs}) {
      padding-top: ${spacing.xs} !important;
    }
  }
  .h-pt-xs--sm {
    @media (min-width: ${breakpoint.sm}) {
      padding-top: ${spacing.xs} !important;
    }
  }
  .h-pt-xs--md {
    @media (min-width: ${breakpoint.md}) {
      padding-top: ${spacing.xs} !important;
    }
  }
  .h-pt-xs--lg {
    @media (min-width: ${breakpoint.lg}) {
      padding-top: ${spacing.xs} !important;
    }
  }
  .h-pt-xs--xl {
    @media (min-width: ${breakpoint.xl}) {
      padding-top: ${spacing.xs} !important;
    }
  }

  /* sm */
  .h-pt-sm {
    padding-top: ${spacing.sm} !important;
  }
  .h-pt-sm--xs {
    @media (min-width: ${breakpoint.xs}) {
      padding-top: ${spacing.sm} !important;
    }
  }
  .h-pt-sm--sm {
    @media (min-width: ${breakpoint.sm}) {
      padding-top: ${spacing.sm} !important;
    }
  }
  .h-pt-sm--md {
    @media (min-width: ${breakpoint.md}) {
      padding-top: ${spacing.sm} !important;
    }
  }
  .h-pt-sm--lg {
    @media (min-width: ${breakpoint.lg}) {
      padding-top: ${spacing.sm} !important;
    }
  }
  .h-pt-sm--xl {
    @media (min-width: ${breakpoint.xl}) {
      padding-top: ${spacing.sm} !important;
    }
  }

  /* md */
  .h-pt-md {
    padding-top: ${spacing.md} !important;
  }
  .h-pt-md--xs {
    @media (min-width: ${breakpoint.xs}) {
      padding-top: ${spacing.md} !important;
    }
  }
  .h-pt-md--sm {
    @media (min-width: ${breakpoint.sm}) {
      padding-top: ${spacing.md} !important;
    }
  }
  .h-pt-md--md {
    @media (min-width: ${breakpoint.md}) {
      padding-top: ${spacing.md} !important;
    }
  }
  .h-pt-md--lg {
    @media (min-width: ${breakpoint.lg}) {
      padding-top: ${spacing.md} !important;
    }
  }
  .h-pt-md--xl {
    @media (min-width: ${breakpoint.xl}) {
      padding-top: ${spacing.md} !important;
    }
  }

  /* lg */
  .h-pt-lg {
    padding-top: ${spacing.lg} !important;
  }
  .h-pt-lg--xs {
    @media (min-width: ${breakpoint.xs}) {
      padding-top: ${spacing.lg} !important;
    }
  }
  .h-pt-lg--sm {
    @media (min-width: ${breakpoint.sm}) {
      padding-top: ${spacing.lg} !important;
    }
  }
  .h-pt-lg--md {
    @media (min-width: ${breakpoint.md}) {
      padding-top: ${spacing.lg} !important;
    }
  }
  .h-pt-lg--lg {
    @media (min-width: ${breakpoint.lg}) {
      padding-top: ${spacing.lg} !important;
    }
  }
  .h-pt-lg--xl {
    @media (min-width: ${breakpoint.xl}) {
      padding-top: ${spacing.lg} !important;
    }
  }

  /* xl */
  .h-pt-xl {
    padding-top: ${spacing.xl} !important;
  }
  .h-pt-xl--xs {
    @media (min-width: ${breakpoint.xs}) {
      padding-top: ${spacing.xl} !important;
    }
  }
  .h-pt-xl--sm {
    @media (min-width: ${breakpoint.sm}) {
      padding-top: ${spacing.xl} !important;
    }
  }
  .h-pt-xl--md {
    @media (min-width: ${breakpoint.md}) {
      padding-top: ${spacing.xl} !important;
    }
  }
  .h-pt-xl--lg {
    @media (min-width: ${breakpoint.lg}) {
      padding-top: ${spacing.xl} !important;
    }
  }
  .h-pt-xl--xl {
    @media (min-width: ${breakpoint.xl}) {
      padding-top: ${spacing.xl} !important;
    }
  }

  /* xxl */
  .h-pt-xxl {
    padding-top: ${spacing.xxl} !important;
  }
  .h-pt-xxl--xs {
    @media (min-width: ${breakpoint.xs}) {
      padding-top: ${spacing.xxl} !important;
    }
  }
  .h-pt-xxl--sm {
    @media (min-width: ${breakpoint.sm}) {
      padding-top: ${spacing.xxl} !important;
    }
  }
  .h-pt-xxl--md {
    @media (min-width: ${breakpoint.md}) {
      padding-top: ${spacing.xxl} !important;
    }
  }
  .h-pt-xxl--lg {
    @media (min-width: ${breakpoint.lg}) {
      padding-top: ${spacing.xxl} !important;
    }
  }
  .h-pt-xxl--xl {
    @media (min-width: ${breakpoint.xl}) {
      padding-top: ${spacing.xxl} !important;
    }
  }

  /* None */
  .h-pt-none {
    padding-top: 0 !important;
  }
  .h-pt-none--xs {
    @media (min-width: ${breakpoint.xs}) {
      padding-top: 0 !important;
    }
  }
  .h-pt-none--sm {
    @media (min-width: ${breakpoint.sm}) {
      padding-top: 0 !important;
    }
  }
  .h-pt-none--md {
    @media (min-width: ${breakpoint.md}) {
      padding-top: 0 !important;
    }
  }
  .h-pt-none--lg {
    @media (min-width: ${breakpoint.lg}) {
      padding-top: 0 !important;
    }
  }
  .h-pt-none--xl {
    @media (min-width: ${breakpoint.xl}) {
      padding-top: 0 !important;
    }
  }

  /*----------  Padding Bottom  ----------*/

  /* tiny */
  .h-pb-tiny {
    padding-bottom: ${spacing.tiny} !important;
  }
  .h-pb-tiny--xs {
    @media (min-width: ${breakpoint.xs}) {
      padding-bottom: ${spacing.tiny} !important;
    }
  }
  .h-pb-tiny--sm {
    @media (min-width: ${breakpoint.sm}) {
      padding-bottom: ${spacing.tiny} !important;
    }
  }
  .h-pb-tiny--md {
    @media (min-width: ${breakpoint.md}) {
      padding-bottom: ${spacing.tiny} !important;
    }
  }
  .h-pb-tiny--lg {
    @media (min-width: ${breakpoint.lg}) {
      padding-bottom: ${spacing.tiny} !important;
    }
  }
  .h-pb-tiny--xl {
    @media (min-width: ${breakpoint.xl}) {
      padding-bottom: ${spacing.tiny} !important;
    }
  }

  /* xxs */
  .h-pb-xxs {
    padding-bottom: ${spacing.xxs} !important;
  }
  .h-pb-xxs--xs {
    @media (min-width: ${breakpoint.xs}) {
      padding-bottom: ${spacing.xxs} !important;
    }
  }
  .h-pb-xxs--sm {
    @media (min-width: ${breakpoint.sm}) {
      padding-bottom: ${spacing.xxs} !important;
    }
  }
  .h-pb-xxs--md {
    @media (min-width: ${breakpoint.md}) {
      padding-bottom: ${spacing.xxs} !important;
    }
  }
  .h-pb-xxs--lg {
    @media (min-width: ${breakpoint.lg}) {
      padding-bottom: ${spacing.xxs} !important;
    }
  }
  .h-pb-xxs--xl {
    @media (min-width: ${breakpoint.xl}) {
      padding-bottom: ${spacing.xxs} !important;
    }
  }

  /* xs */
  .h-pb-xs {
    padding-bottom: ${spacing.xs} !important;
  }
  .h-pb-xs--xs {
    @media (min-width: ${breakpoint.xs}) {
      padding-bottom: ${spacing.xs} !important;
    }
  }
  .h-pb-xs--sm {
    @media (min-width: ${breakpoint.sm}) {
      padding-bottom: ${spacing.xs} !important;
    }
  }
  .h-pb-xs--md {
    @media (min-width: ${breakpoint.md}) {
      padding-bottom: ${spacing.xs} !important;
    }
  }
  .h-pb-xs--lg {
    @media (min-width: ${breakpoint.lg}) {
      padding-bottom: ${spacing.xs} !important;
    }
  }
  .h-pb-xs--xl {
    @media (min-width: ${breakpoint.xl}) {
      padding-bottom: ${spacing.xs} !important;
    }
  }

  /* sm */
  .h-pb-sm {
    padding-bottom: ${spacing.sm} !important;
  }
  .h-pb-sm--xs {
    @media (min-width: ${breakpoint.xs}) {
      padding-bottom: ${spacing.sm} !important;
    }
  }
  .h-pb-sm--sm {
    @media (min-width: ${breakpoint.sm}) {
      padding-bottom: ${spacing.sm} !important;
    }
  }
  .h-pb-sm--md {
    @media (min-width: ${breakpoint.md}) {
      padding-bottom: ${spacing.sm} !important;
    }
  }
  .h-pb-sm--lg {
    @media (min-width: ${breakpoint.lg}) {
      padding-bottom: ${spacing.sm} !important;
    }
  }
  .h-pb-sm--xl {
    @media (min-width: ${breakpoint.xl}) {
      padding-bottom: ${spacing.sm} !important;
    }
  }

  /* md */
  .h-pb-md {
    padding-bottom: ${spacing.md} !important;
  }
  .h-pb-md--xs {
    @media (min-width: ${breakpoint.xs}) {
      padding-bottom: ${spacing.md} !important;
    }
  }
  .h-pb-md--sm {
    @media (min-width: ${breakpoint.sm}) {
      padding-bottom: ${spacing.md} !important;
    }
  }
  .h-pb-md--md {
    @media (min-width: ${breakpoint.md}) {
      padding-bottom: ${spacing.md} !important;
    }
  }
  .h-pb-md--lg {
    @media (min-width: ${breakpoint.lg}) {
      padding-bottom: ${spacing.md} !important;
    }
  }
  .h-pb-md--xl {
    @media (min-width: ${breakpoint.xl}) {
      padding-bottom: ${spacing.md} !important;
    }
  }

  /* lg */
  .h-pb-lg {
    padding-bottom: ${spacing.lg} !important;
  }
  .h-pb-lg--xs {
    @media (min-width: ${breakpoint.xs}) {
      padding-bottom: ${spacing.lg} !important;
    }
  }
  .h-pb-lg--sm {
    @media (min-width: ${breakpoint.sm}) {
      padding-bottom: ${spacing.lg} !important;
    }
  }
  .h-pb-lg--md {
    @media (min-width: ${breakpoint.md}) {
      padding-bottom: ${spacing.lg} !important;
    }
  }
  .h-pb-lg--lg {
    @media (min-width: ${breakpoint.lg}) {
      padding-bottom: ${spacing.lg} !important;
    }
  }
  .h-pb-lg--xl {
    @media (min-width: ${breakpoint.xl}) {
      padding-bottom: ${spacing.lg} !important;
    }
  }

  /* xl */
  .h-pb-xl {
    padding-bottom: ${spacing.xl} !important;
  }
  .h-pb-xl--xs {
    @media (min-width: ${breakpoint.xs}) {
      padding-bottom: ${spacing.xl} !important;
    }
  }
  .h-pb-xl--sm {
    @media (min-width: ${breakpoint.sm}) {
      padding-bottom: ${spacing.xl} !important;
    }
  }
  .h-pb-xl--md {
    @media (min-width: ${breakpoint.md}) {
      padding-bottom: ${spacing.xl} !important;
    }
  }
  .h-pb-xl--lg {
    @media (min-width: ${breakpoint.lg}) {
      padding-bottom: ${spacing.xl} !important;
    }
  }
  .h-pb-xl--xl {
    @media (min-width: ${breakpoint.xl}) {
      padding-bottom: ${spacing.xl} !important;
    }
  }

  /* xxl */
  .h-pb-xxl {
    padding-bottom: ${spacing.xxl} !important;
  }
  .h-pb-xxl--xs {
    @media (min-width: ${breakpoint.xs}) {
      padding-bottom: ${spacing.xxl} !important;
    }
  }
  .h-pb-xxl--sm {
    @media (min-width: ${breakpoint.sm}) {
      padding-bottom: ${spacing.xxl} !important;
    }
  }
  .h-pb-xxl--md {
    @media (min-width: ${breakpoint.md}) {
      padding-bottom: ${spacing.xxl} !important;
    }
  }
  .h-pb-xxl--lg {
    @media (min-width: ${breakpoint.lg}) {
      padding-bottom: ${spacing.xxl} !important;
    }
  }
  .h-pb-xxl--xl {
    @media (min-width: ${breakpoint.xl}) {
      padding-bottom: ${spacing.xxl} !important;
    }
  }

  /* None */
  .h-pb-none {
    padding-bottom: 0 !important;
  }
  .h-pb-none--xs {
    @media (min-width: ${breakpoint.xs}) {
      padding-bottom: 0 !important;
    }
  }
  .h-pb-none--sm {
    @media (min-width: ${breakpoint.sm}) {
      padding-bottom: 0 !important;
    }
  }
  .h-pb-none--md {
    @media (min-width: ${breakpoint.md}) {
      padding-bottom: 0 !important;
    }
  }
  .h-pb-none--lg {
    @media (min-width: ${breakpoint.lg}) {
      padding-bottom: 0 !important;
    }
  }
  .h-pb-none--xl {
    @media (min-width: ${breakpoint.xl}) {
      padding-bottom: 0 !important;
    }
  }
`;
