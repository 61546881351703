import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { css } from '@emotion/core';
import styles from '../../../styles';
import LocalizedLink from '../../locale/LocalizedLink';

const NavItems = ({ items, onClick }) => (
  <ul css={navItemStyle}>
    {items.map(item => (
      <li key={item.id}>
        {item.href ? (
          <a href={item.href} target='_blank' rel='noopener noreferrer' onClick={onClick}>
            <FormattedMessage {...item.label} />
          </a>
        ) : (
          <LocalizedLink
            to={item.to}
            activeClassName='is-active'
            partiallyActive={true}
            onClick={onClick}>
            <FormattedMessage {...item.label} />
          </LocalizedLink>
        )}
      </li>
    ))}
  </ul>
);

NavItems.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      to: PropTypes.string,
      href: PropTypes.string,
      label: PropTypes.object.isRequired
    })
  ).isRequired,
  onClick: PropTypes.func
};

const navItemStyle = css`
  a {
    text-decoration: none;
    color: ${styles.color.black};
    transition: color ${styles.easing.time} ${styles.easing.main};

    &[disabled],
    &.is-disabled {
      cursor: not-allowed;
      color: ${styles.color.lightPurple};
    }

    &:not([disabled]):not(.is-disabled) {
      &:hover,
      &.is-active {
        color: ${styles.color.purple};
      }
      &:active {
        background-color: ${styles.color.green};
        color: ${styles.color.black};
      }
    }
  }
`;

export default NavItems;
