import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { css } from '@emotion/core';
import App from '../../App';
import Header from './Header/HeaderContainer';
import Footer from './Footer';
import styles from '../../styles';

const BaseLayout = ({ locale, render, renderRelated }) => (
  <App locale={locale}>
    <div css={style.content}>
      <Header locale={locale} />
      <BaseLayoutContent render={(intl) => render({ intl })} />
    </div>
    {typeof renderRelated === 'function' && renderRelated()}
    <Footer style={style.footer} locale={locale} />
  </App>
);

const BaseLayoutContent = injectIntl(({ render, intl }) => <main>{render(intl)}</main>);

BaseLayout.propTypes = {
  locale: PropTypes.string.isRequired,
  render: PropTypes.func.isRequired,
  renderRelated: PropTypes.func,
};

const style = {
  content: css`
    /* Add padding top for fixed header */
    padding-top: 6.159rem;
    @media (min-width: ${styles.breakpoint.xs}) {
      padding-top: 6.8rem;
    }
    @media (min-width: ${styles.breakpoint.sm}) {
      padding-top: 7.119rem;
    }
    @media (min-width: ${styles.breakpoint.mobileNav}) {
      position: relative;
      min-height: 100vh;
      padding-bottom: 8rem;
    }
  `,
  footer: css`
    @media (min-width: ${styles.breakpoint.mobileNav}) {
      margin-top: -8rem;
    }
  `,
};

export default BaseLayout;
