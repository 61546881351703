import { css } from '@emotion/core';
import reset from './reset';
import vendor from './vendor';
import grid from './grid';
import breakpoint from './breakpoint';
import typography from './typography';
import font, { fonts } from './font';
import { spacingHelpers } from './spacing';
import color from './color';
import templates from './templates';

const base = css`
  ${reset}
  ${vendor}
  ${grid}
  ${fonts}
  ${spacingHelpers}

  html {
    font-size: 10px;

    @media (min-width: ${breakpoint.xxl}) {
      font-size: 12px;
    }
  }

  body {
    font-family: ${font.family.main};
    font-weight: ${font.weight.normal};
    color: ${color.black};
    font-size: ${font.size.p};
    line-height: 1.5;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    scroll-margin-top: 85px;
  }

  ${typography}
  ${templates}
`;

export default base;
