import { css } from '@emotion/core';
import breakpoint from './breakpoint';
import font from './font';
import color from './color';
import easing from './easing';

const typography = css`
  .h1 {
    font-family: ${font.family.heading};
    font-size: ${font.size.h1};
    line-height: 1.3;
  }

  .h2 {
    font-weight: ${font.weight.black};
    font-size: 3.8rem;
    line-height: 1.2;
    @media (min-width: ${breakpoint.sm}) {
      font-size: ${font.size.h2};
    }
  }

  .h3 {
    font-weight: ${font.weight.bold};
    font-size: 2.8rem;
    @media (min-width: ${breakpoint.sm}) {
      font-size: ${font.size.h3};
    }
  }

  .h4 {
    font-weight: ${font.weight.bold};
    font-size: ${font.size.h4};
  }

  .h5 {
    font-weight: ${font.weight.bold};
    font-size: ${font.size.h5};
  }

  .h6 {
    font-weight: ${font.weight.bold};
    font-size: ${font.size.h6};
  }

  .p {
    line-height: 1.9;
  }

  .p1 {
    font-size: ${font.size.p1};
  }

  .a {
    text-decoration: none;
    color: ${color.purple};
    transition: color ${easing.time} ${easing.main};
    &:hover {
      color: ${color.medPurple};
    }
    &:active {
      color: ${color.darkPurple};
    }
  }
`;

export default typography;
