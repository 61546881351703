import { css } from '@emotion/core';

export const fonts = css`
  @font-face {
    font-family: 'Fabrik';
    src: url(${require('../assets/fonts/Fabrik-Bold.eot')});
    src: url(${require('../assets/fonts/Fabrik-Bold.eot?#iefix')}) format('embedded-opentype'),
      url(${require('../assets/fonts/Fabrik-Bold.woff2')}) format('woff2'),
      url(${require('../assets/fonts/Fabrik-Bold.woff')}) format('woff');
    font-weight: bold;
  }

  @font-face {
    font-family: 'Heebo';
    src: url(${require('../assets/fonts/Heebo-Regular.ttf')});
    font-weight: normal;
  }

  @font-face {
    font-family: 'Heebo';
    src: url(${require('../assets/fonts/Heebo-Bold.ttf')});
    font-weight: bold;
  }

  @font-face {
    font-family: 'Heebo';
    src: url(${require('../assets/fonts/Heebo-Medium.ttf')});
    font-weight: 600;
  }

  @font-face {
    font-family: 'Heebo';
    src: url(${require('../assets/fonts/Heebo-Black.ttf')});
    font-weight: 900;
  }

  @font-face {
    font-family: 'Menlo';
    src: url(${require('../assets/fonts/Menlo-Regular.ttf')});
    font-weight: normal;
  }
`;

const stack =
  '"HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif';

const font = {
  family: {
    main: `Heebo, ${stack}`,
    heading: `Fabrik, ${stack}`,
    mono: 'Menlo, monospace'
  },
  weight: {
    normal: 'normal',
    bold: 'bold',
    medium: '600',
    black: '900'
  },
  size: {
    h1: '4.4rem',
    h2: '5rem',
    h3: '3.2rem',
    h4: '2.4rem',
    h5: '2.2rem',
    h6: '1.8rem',
    p: '1.6rem',
    p1: '1.4rem'
  }
};

export default font;
