import React from 'react';
import { Global, css } from '@emotion/core';

export default () => (
  <>
    <symbol id='icon-search' viewBox='0 0 32 32'>
      <path d='M22.843 22.843c4.86-4.86 4.86-12.739 0-17.599s-12.739-4.86-17.599 0c-4.86 4.86-4.86 12.739 0 17.599s12.739 4.86 17.599 0zM20.329 20.329c-3.471 3.471-9.099 3.471-12.571 0s-3.471-9.099 0-12.571c3.471-3.471 9.099-3.471 12.571 0s3.471 9.099 0 12.571z' />
      <path d='M19.072 21.586l2.514-2.514 10.057 10.057-2.514 2.514-10.057-10.057zM20.329 20.329l10.057 10.057h-2.514l2.514-2.514v2.514l-10.057-10.057h2.514l-2.514 2.514v-2.514z' />
    </symbol>

    <Global
      styles={css`
        .icon-search {
          width: 3.2em;
          height: 3.2em;
        }
      `}
    />
  </>
);
