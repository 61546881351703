import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Helmet from 'react-helmet';
import { injectIntl, defineMessages } from 'react-intl';
import { DEFAULT_LANG } from './locale/LocaleProvider';

const messages = defineMessages({
  description: {
    id: 'site.description',
    defaultMessage:
      'PolySwarm is a crowdsourced threat detection marketplace where security experts & AV companies compete to protect you.'
  }
});

const SEO = ({ title, description, locale, pathname, meta, intl }) => {
  const { site } = useStaticQuery(
    graphql`
      {
        site {
          siteMetadata {
            title
            siteUrl
            author
          }
        }
      }
    `
  );

  const metaDescription = description || intl.formatMessage(messages.description);
  const url = `${site.siteMetadata.siteUrl}${pathname}`;

  // TODO: add og:image and twitter:image
  return (
    <Helmet
      htmlAttributes={{ lang: locale }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      link={[{ rel: 'alternate', hrefLang: locale, href: url }, { rel: 'canonical', href: url }]}
      meta={[
        {
          name: 'description',
          content: metaDescription
        },
        {
          property: 'og:locale',
          content: locale.replace('-', '_')
        },
        {
          property: 'og:type',
          content: 'website'
        },
        {
          property: 'og:title',
          content: title
        },
        {
          property: 'og:description',
          content: metaDescription
        },
        {
          property: 'og:url',
          content: url
        },
        {
          property: 'og:site_name',
          content: site.siteMetadata.title
        },
        {
          property: 'og:image',
          content: 'https://polyswarm.io/assets/images/social/twitter-card.png'
        },
        {
          property: 'og:image:secure_url',
          content: 'https://polyswarm.io/assets/images/social/twitter-card.png'
        },
        {
          property: 'og:image:width:',
          content: '1200'
        },
        {
          property: 'og:image:height',
          content: '500'
        },
        {
          name: 'twitter:card',
          content: 'summary_large_image'
        },
        {
          name: 'twitter:description',
          content: metaDescription
        },
        {
          name: 'twitter:title',
          content: title
        },
        {
          name: 'twitter:site',
          content: site.siteMetadata.author
        },
        {
          property: 'twitter:image',
          content: 'https://polyswarm.io/assets/images/social/twitter-card.png'
        },
        {
          name: 'twitter:creator',
          content: site.siteMetadata.author
        },
        {
          name: 'docsearch:language',
          content: locale
        },
        ...meta
      ]}
    />
  );
};

SEO.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  locale: PropTypes.string,
  pathame: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object)
};

SEO.defaultProps = {
  description: '',
  locale: DEFAULT_LANG,
  pathname: '',
  meta: []
};

export default injectIntl(SEO);
