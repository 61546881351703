import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';

const Icon = ({ style, name, title }) => (
  <svg className={`icon-${name}`} css={[ownStyle, style]}>
    <title>{title}</title>
    <use xlinkHref={`#icon-${name}`} />
  </svg>
);

Icon.propTypes = {
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  name: PropTypes.string.isRequired,
  title: PropTypes.string
};

const ownStyle = css`
  display: inline-block;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
`;

export default Icon;
