import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import { css } from '@emotion/core';
import { MAIN_SITE_URL } from '../../../utils/constants';
import { Fab } from '@material-ui/core';
import styles from '../../../styles';
import LanguageSelect from './LanguageSelect';
import NavItems from '../nav/NavItems';

const messages = defineMessages({
  company: {
    id: 'footer.nav.company',
    defaultMessage: 'Company',
  },
  help: {
    id: 'footer.nav.help',
    defaultMessage: 'Help',
  },
  privacy: {
    id: 'footer.nav.privacy',
    defaultMessage: 'Privacy Policy',
  },
  terms: {
    id: 'footer.nav.terms',
    defaultMessage: 'Terms of Service',
  },
});

const navItems = [
  {
    id: 1,
    href: MAIN_SITE_URL,
    label: messages.company,
  },
  {
    id: 2,
    href: `${MAIN_SITE_URL}/privacy`,
    label: messages.privacy,
  },
  {
    id: 3,
    href: `${MAIN_SITE_URL}/terms`,
    label: messages.terms,
  },
];

const Footer = ({ style, locale, intl }) => (
  <>
    <div css={ownStyle.helpBtnWrap}>
      <Fab
        component='a'
        href='https://discord.gg/p8ktqUc'
        target='_blank'
        rel='noopener noreferrer'
        color='primary'
        variant='extended'>
        <span css={ownStyle.helpBtn}>{intl.formatMessage(messages.help)}</span>
      </Fab>
    </div>
    <footer css={[ownStyle.root, style]}>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <div css={ownStyle.content}>
              <div css={ownStyle.navigation}>
                <LanguageSelect initialValue={locale} />
                <FooterNav items={navItems} />
              </div>
              <p css={ownStyle.copyright}>
                {new Date().getFullYear()} &copy;{' '}
                <FormattedMessage id='company.fullname' defaultMessage='PolySwarm Pte. Ltd.' />
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </>
);

Footer.propTypes = {
  locale: PropTypes.string.isRequired,
};

const ownStyle = {
  root: css`
    position: relative;
    z-index: 1;
    background-color: ${styles.color.white};
  `,
  helpBtnWrap: css`
    position: fixed;
    z-index: 2;
    bottom: ${styles.spacing.sm};
    left: ${styles.spacing.sm};
  `,
  helpBtn: css`
    font-size: ${styles.font.size.p1};
    font-family: ${styles.font.family.main};
    font-weight: ${styles.font.weight.bold};
    text-transform: none;
  `,
  content: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    border-top: 1px solid ${styles.border.color.grey};
    padding: 2.35rem 0;
    @media (min-width: ${styles.breakpoint.mobileNav}) {
      flex-direction: row;
    }
  `,
  navigation: css`
    display: flex;
    align-items: center;
    flex-direction: column;
    @media (min-width: ${styles.breakpoint.mobileNav}) {
      flex-direction: row;
    }
  `,
  copyright: css`
    font-size: ${styles.font.size.p1};
    color: ${styles.color.medLightGrey};
  `,
};

const FooterNav = ({ items }) => (
  <nav css={footerNavStyle}>
    <NavItems items={items} />
  </nav>
);

const footerNavStyle = css`
  padding: ${styles.spacing.xxs} 0;
  @media (min-width: ${styles.breakpoint.mobileNav}) {
    padding: 0;
  }
  li {
    text-align: center;
    margin-bottom: ${styles.spacing.tiny};
    &:last-child {
      margin-bottom: 0.4rem;
    }
    @media (min-width: ${styles.breakpoint.mobileNav}) {
      display: inline;
      text-align: left;
      margin-bottom: 0;
      margin-left: ${styles.spacing.xs};
    }
    a {
      font-size: ${styles.font.size.p1};
      text-decoration: none;
      color: ${styles.color.lightGrey};
      transition: color ${styles.easing.time} ${styles.easing.main};
      &:hover {
        color: ${styles.color.purple};
      }
      &:active {
        color: ${styles.color.medPurple};
      }
    }
  }
`;

export default injectIntl(Footer);
