import React, { Component } from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import { IntlProvider } from 'react-intl';
import localizePath from '../../utils/localizePath';
import languages from '../../../locale/localeData/data.json';
import Snackbar from '../Snackbar';

export const DEFAULT_LANG = 'en-US';

export const langs = {
  'en-US': {
    name: 'English',
    text: 'This page is available in English.',
    buttonText: 'View now'
  },
  'ja-JP': {
    name: '日本語',
    text: 'このページは日本語でもご利用いただけます。',
    buttonText: '今すぐ表示'
  },
  'zh-CN': {
    name: '简体中文',
    text: '简体中文版',
    buttonText: '现在前往查看'
  },
  'zh-TW': {
    name: '繁體中文',
    text: '繁體中文版',
    buttonText: '現在前往查看'
  }
};

let isLangNotifyDismissed = false;

class LocaleProvider extends Component {
  state = { isNativeLanguage: true, text: '', buttonText: '', link: '/' };

  componentDidMount() {
    this._checkLang();
  }

  render() {
    const { locale, children } = this.props;
    const { isNativeLanguage, text, buttonText, link } = this.state;
    return (
      <IntlProvider locale={locale} messages={languages[locale]}>
        <>
          {!isNativeLanguage && !isLangNotifyDismissed && (
            <Snackbar
              id='locale-snackbar'
              openOnMount={true}
              renderActionBtn={() => (
                <Link to={link} onClick={this._handleSnackbarClose}>
                  {buttonText}
                </Link>
              )}
              onClose={this._handleSnackbarClose}>
              <span>{text}</span>
            </Snackbar>
          )}
          {children}
        </>
      </IntlProvider>
    );
  }

  /**
   * Check the browser language against the current locale settings.
   */
  _checkLang = () => {
    const { locale } = this.props;
    const { pathname } = window.location;
    const currentLang = this.props.locale.substring(0, 2);
    const browserLang = navigator.language.substring(0, 2);

    if (currentLang !== browserLang) {
      // Find exact match
      if (Object.keys(langs).includes(navigator.language)) {
        const { text, buttonText } = langs[navigator.language];
        const link = localizePath(pathname, locale, navigator.language);
        this.setState({ isNativeLanguage: false, text, buttonText, link });
      } else {
        // Find first closest match
        Object.keys(langs).every(lang => {
          if (lang.substring(0, 2) === browserLang) {
            const { text, buttonText } = langs[lang];
            const link = localizePath(pathname, locale, lang);
            this.setState({ isNativeLanguage: false, text, buttonText, link });
            return false;
          }
          return true;
        });
      }
    }
  };

  _handleSnackbarClose = () => {
    isLangNotifyDismissed = true;
  };
}

LocaleProvider.propTypes = {
  locale: PropTypes.string.isRequired
};

export default LocaleProvider;
