import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import { Snackbar as MaterialSnackbar } from '@material-ui/core';
import styles from '../../styles';
import Icon from '../Icon';

class Snackbar extends Component {
  state = { open: false };

  componentDidMount() {
    if (this.props.openOnMount) {
      this._handleOpen();
    }
  }

  render() {
    const { id, autoHide, renderActionBtn, renderBtnContent, children } = this.props;
    const { open } = this.state;
    return (
      <>
        {renderBtnContent && <button onClick={this._handleOpen}>{renderBtnContent()}</button>}
        <MaterialSnackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          key={id}
          open={open}
          onClose={this._handleClose}
          autoHideDuration={autoHide ? 5000 : null}
          ContentProps={{ 'aria-describedby': id }}
          message={
            <span id={id} css={style.text}>
              {children}
            </span>
          }
          action={
            <>
              {renderActionBtn && <div css={style.buttons}>{renderActionBtn()}</div>}
              <div css={style.buttons}>
                <button css={style.btn} onClick={this._handleClose}>
                  <Icon style={style.close} name='close' />
                </button>
              </div>
            </>
          }
        />
      </>
    );
  }

  _handleOpen = () => {
    this.setState({ open: true });
  };

  _handleClose = () => {
    this.setState({ open: false });
    this.props.onClose();
  };
}

Snackbar.propTypes = {
  id: PropTypes.string.isRequired,
  openOnMount: PropTypes.bool,
  autoHide: PropTypes.bool,
  renderActionBtn: PropTypes.func,
  renderBtnContent: PropTypes.func,
  onClose: PropTypes.func
};

Snackbar.defaultProps = {
  autoHide: true
};

const style = {
  btn: css`
    margin-left: ${styles.spacing.xxs};
  `,
  close: css`
    font-size: 0.4rem;
    margin: 0 0.2rem 0.2rem;
    vertical-align: middle;
  `,
  text: css`
    font-size: ${styles.font.size.p1};
    font-family: ${styles.font.family.main};
    line-height: 1.3;
  `,
  buttons: css`
    a,
    button {
      cursor: pointer;
      display: block;
      line-height: 1.3;
      text-decoration: none;
      color: ${styles.color.white};
      font-size: ${styles.font.size.p1};
      font-family: ${styles.font.family.main};
      font-weight: ${styles.font.weight.medium};
      transition: color ${styles.easing.time} ${styles.easing.main};
      &:hover {
        color: ${styles.color.green};
      }
    }
  `
};

export default Snackbar;
