import React from 'react';
import { css } from '@emotion/core';

import IconWordmark from './icons/IconWordmark';
import IconSearch from './icons/IconSearch';
import IconArrow from './icons/IconArrow';
import IconPlus from './icons/IconPlus';
import IconClose from './icons/IconClose';

export const ICON_REGISTRY = {
  wordmark: IconWordmark,
  search: IconSearch,
  arrow: IconArrow,
  plus: IconPlus,
  close: IconClose
};

const IconBase = () => (
  <svg css={style} width='0' height='0' version='1.1' xmlns='http://www.w3.org/2000/svg'>
    <defs>
      {Object.entries(ICON_REGISTRY).map(([name, element]) =>
        React.createElement(element, { key: name })
      )}
    </defs>
  </svg>
);

const style = css`
  position: absolute;
  width: 0;
  height: 0;
`;

export default IconBase;
