import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { injectIntl } from 'react-intl';
import localizePath from '../../utils/localizePath';

const LocalizedLink = ({ className, style, to, intl, children, ...rest }) => (
  <Link className={className} css={style} to={localizePath(to, intl.locale)} {...rest}>
    {children}
  </Link>
);

LocalizedLink.propTypes = {
  to: PropTypes.string.isRequired
};

export default injectIntl(LocalizedLink);
