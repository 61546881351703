import React from 'react';
import buttonStyle from '../Button.styles';

const makeButton = WrappedComponent => ({
  className,
  style,
  variant,
  disabled,
  dataCy,
  ...rest
}) => (
  <WrappedComponent
    className={className}
    css={[variant ? buttonStyle[variant] : buttonStyle.base, style]}
    disabled={disabled}
    data-cy={dataCy}
    onClick={e => disabled && e.preventDefault()}
    {...rest}
  />
);

export default makeButton;
