import React, { Component } from 'react';
import PropTypes from 'prop-types';
import config from '../../../config';
import Header from '.';

/**
 * Connects DocSearch to Header component.
 * See: https://community.algolia.com/docsearch/dropdown.html
 */
class HeaderContainer extends Component {
  componentDidMount() {
    window.docsearch({
      apiKey: config.docsearchApiKey,
      indexName: config.docsearchIndex,
      algoliaOptions: { facetFilters: [`lang:${this.props.locale}`] },
      inputSelector: '#algolia-doc-search',
    });
  }

  render() {
    return <Header searchId='algolia-doc-search' />;
  }
}

HeaderContainer.propTypes = {
  locale: PropTypes.string.isRequired,
};

export default HeaderContainer;
