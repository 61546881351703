import { css } from '@emotion/core';
import syntaxHighlighting from './syntaxHighlighting';
import algoliaStyles from './algoliaStyles';

const vendor = css`
  ${syntaxHighlighting}
  ${algoliaStyles}
`;

export default vendor;
