import { css } from '@emotion/core';
import styles from '../../styles';

const base = css`
  cursor: pointer;

  &[disabled] {
    cursor: not-allowed;
  }
`;

const normal = css`
  ${base}
  font-size: ${styles.font.size.p1};
  line-height: 1.5;
  display: inline-block;
  text-decoration: none;
  font-family: ${styles.font.family.main};
  font-weight: ${styles.font.weight.bold};
  color: ${styles.color.white};
  background-color: ${styles.color.purple};
  border: 2px solid ${styles.color.purple};
  border-radius: ${styles.border.radiusXl};
  padding: 0.5em 1em;
  transition: color ${styles.easing.time} ${styles.easing.main},
    background-color ${styles.easing.time} ${styles.easing.main},
    border-color ${styles.easing.time} ${styles.easing.main};
  text-align: center;

  @media (min-width: ${styles.breakpoint.sm}) {
    font-size: ${styles.font.size.h6};
  }

  &[disabled] {
    background-color: ${styles.color.lightPurple};
    border-color: ${styles.color.lightPurple};
  }

  &:not([disabled]) {
    &:hover {
      background-color: ${styles.color.medPurple};
      border-color: ${styles.color.medPurple};
    }
    &:active {
      background-color: ${styles.color.darkPurple};
      border-color: ${styles.color.darkPurple};
    }
  }
`;

const light = css`
  ${normal}
  font-size: 1.4rem;
  font-weight: ${styles.font.weight.medium};
  color: ${styles.color.purple};
  background-color: transparent;

  &:not([disabled]) {
    &:hover {
      color: ${styles.color.white};
      background-color: ${styles.color.medPurple};
      border-color: ${styles.color.medPurple};
    }
    &:active {
      background-color: ${styles.color.darkPurple};
      border-color: ${styles.color.darkPurple};
    }
  }
`;

const large = css`
  ${normal}
  font-size: ${styles.font.size.h6};
  padding: 0.7em 2.6em;
  @media (min-width: ${styles.breakpoint.sm}) {
    font-size: ${styles.font.size.h5};
    padding: 0.7em 3em;
  }
`;

export default { base, normal, light, large };
