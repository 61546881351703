import { DEFAULT_LANG, langs } from '../components/locale/LocaleProvider';

const localizePath = (path, locale, toLocale) => {
  let localizedPath = path;
  Object.keys(langs).forEach(
    lang => (localizedPath = localizedPath.replace(new RegExp(`/${lang}(/|$)`, 'gm'), '/'))
  );
  const lang = toLocale || locale;
  return lang === DEFAULT_LANG ? localizedPath : `/${lang}${localizedPath}`;
};

export default localizePath;
