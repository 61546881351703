const color = {
  // primary
  white: '#ffffff',
  offWhite: '#f8f7fc',
  black: '#3f3f3f',

  // grey
  xxxLightGrey: '#eeeef1',
  xxLightGrey: '#e3e3ed',
  xLightGrey: '#74758C',
  medLightGrey: '#6C6C75',
  lightGrey: '#656573',
  grey: '#5f5f61',
  medGrey: '#414141',

  // purple
  xxLightPurple: '#fbf9fe',
  xLightPurple: '#f8f5fe',
  lightPurple: '#dbcdf8',
  medLightPurple: '#b69cf5',
  purple: '#6d3aec',
  medPurple: '#6033d3',
  darkPurple: '#512ab2',

  // green
  green: '#7bfa7f',
  medGreen: '#64cc67',

  // red
  lightRed: '#FF5C6F',
  red: '#ff455b',
  medRed: '#f8001d',
  darkRed: '#e8001b',
  medDarkRed: '#dd4a68',
  xDarkRed: '#D32F42',

  // blue
  blue: '#7897ff',

  // yellow
  yellow: '#ffaa00',
};

export default color;
