import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { css } from '@emotion/core';
import styles from '../../../../styles';

const Hamburger = ({ isOpen, onClick, ariaControls }) => (
  <button css={style.root} onClick={onClick} aria-controls={ariaControls} aria-expanded={isOpen}>
    <FormattedMessage id='button.menu' defaultMessage='Main Menu' />
    <span css={style.lines(isOpen)}>
      <span />
      <span />
      <span />
    </span>
  </button>
);

Hamburger.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
  ariaControls: PropTypes.string
};

const style = {
  root: css`
    cursor: pointer;
    outline: none;
    display: block;
    font-size: 0;
    height: 32px;
    width: 42px;
    padding: 10px;
    color: ${styles.color.xLightGrey};
    transition: color ${styles.easing.time} ${styles.easing.main};
    &:hover,
    &:focus {
      color: ${styles.color.purple};
    }
  `,
  lines: isOpen => css`
    display: block;
    position: relative;
    height: 100%;
    width: 100%;
    transform: ${isOpen ? 'rotate(135deg)' : 'rotate(0)'};
    transition: transform 0.35s ${styles.easing.authenticMotion};

    span {
      display: block;
      width: 100%;
      height: 2px;
      background-color: currentColor;

      &:nth-of-type(1) {
        position: absolute;
        top: ${isOpen ? '50%' : '0'};
        transform: ${isOpen ? 'translateY(-50%) rotate(90deg)' : 'translateY(0) rotate(0)'};
        left: 0;
        transition: top 0.1s ${styles.easing.main}, transform 0.1s ${styles.easing.main};
      }

      &:nth-of-type(2) {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
      }

      &:nth-of-type(3) {
        position: absolute;
        bottom: ${isOpen ? '50%' : '0'};
        transform: ${isOpen ? 'translateY(50%)' : 'translateY(0)'};
        left: 0;
        transition: bottom 0.1s ${styles.easing.main}, transform 0.1s ${styles.easing.main};
      }
    }
  `
};

export default Hamburger;
