import React from 'react';
import { Global, css } from '@emotion/core';

export default () => (
  <>
    <symbol id='icon-plus' viewBox='0 0 32 32'>
      <path d='M18.667 13.333h10.667c1.473 0 2.667 1.194 2.667 2.667s-1.194 2.667-2.667 2.667h-10.667v10.667c0 1.473-1.194 2.667-2.667 2.667s-2.667-1.194-2.667-2.667v-10.667h-10.667c-1.473 0-2.667-1.194-2.667-2.667s1.194-2.667 2.667-2.667h10.667v-10.667c0-1.473 1.194-2.667 2.667-2.667s2.667 1.194 2.667 2.667v10.667z' />
    </symbol>

    <Global
      styles={css`
        .icon-plus {
          width: 3.2em;
          height: 3.2em;
        }
      `}
    />
  </>
);
