import breakpoint from './breakpoint';
import spacing from './spacing';
import font from './font';
import color from './color';
import easing from './easing';
import border from './border';
import shadow from './shadow';

const styles = {
  breakpoint,
  spacing,
  font,
  color,
  easing,
  border,
  shadow
};

export default styles;
