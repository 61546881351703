import { css } from '@emotion/core';
import font from '../font';
import color from '../color';
import border from '../border';

const syntaxHighlighting = css`
  code[class*='language-'],
  pre[class*='language-'] {
    background: none;
    text-shadow: 0 1px white;
    font-family: ${font.family.mono};
    font-size: 1em;
    text-align: left;
    white-space: pre;
    word-spacing: normal;
    word-break: normal;
    word-wrap: normal;
    line-height: 1.5;

    -moz-tab-size: 4;
    -o-tab-size: 4;
    tab-size: 4;

    -webkit-hyphens: none;
    -moz-hyphens: none;
    -ms-hyphens: none;
    hyphens: none;
  }

  pre[class*='language-']::-moz-selection,
  pre[class*='language-'] ::-moz-selection,
  code[class*='language-']::-moz-selection,
  code[class*='language-'] ::-moz-selection {
    text-shadow: none;
    background: #b3d4fc;
  }

  pre[class*='language-']::selection,
  pre[class*='language-'] ::selection,
  code[class*='language-']::selection,
  code[class*='language-'] ::selection {
    text-shadow: none;
    background: #b3d4fc;
  }

  @media print {
    code[class*='language-'],
    pre[class*='language-'] {
      text-shadow: none;
    }
  }

  /* Code blocks */
  pre[class*='language-'] {
    padding: 2rem;
    overflow: auto;
  }

  :not(pre) > code[class*='language-'],
  pre[class*='language-'] {
    background: ${color.xxLightPurple};
    border-radius: ${border.radius};
  }

  /* Inline code */
  :not(pre) > code[class*='language-'] {
    background: ${color.xxxLightGrey};
    padding: 0.2rem 0.5rem;
    border-radius: ${border.radius};
    font-family: ${font.family.mono};
    white-space: normal;
  }

  .token.comment,
  .token.prolog,
  .token.doctype,
  .token.cdata {
    color: ${color.xLightGrey};
  }

  .namespace {
    opacity: 0.7;
  }

  .token.property,
  .token.interpolation,
  .token.tag,
  .token.boolean,
  .token.number,
  .token.constant,
  .token.symbol,
  .token.deleted {
    color: ${color.medDarkRed};
  }

  .token.string,
  .token.char,
  .token.builtin,
  .token.inserted {
    color: ${color.medGreen};
  }

  .token.punctuation,
  .token.operator,
  .token.entity,
  .token.url,
  .language-css .token.string,
  .style .token.string {
    color: ${color.lightGrey};
    background: hsla(0, 0%, 100%, 0.5);
  }

  .token.selector,
  .token.attr-name,
  .token.atrule,
  .token.attr-value,
  .token.keyword,
  .token.regex,
  .token.important,
  .token.variable {
    color: ${color.darkPurple};
  }

  .token.parameter,
  .token.class-name {
    color: ${color.yellow};
  }

  .token.function {
    color: ${color.blue};
  }

  .token.important,
  .token.bold {
    font-weight: bold;
  }
  .token.italic {
    font-style: italic;
  }

  .token.entity {
    cursor: help;
  }
`;

export default syntaxHighlighting;
