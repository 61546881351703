import { createMuiTheme } from '@material-ui/core/styles';
import styles from '../';

const materialTheme = createMuiTheme({
  props: {
    MuiButtonBase: {
      disableRipple: true
    }
  },
  typography: {
    useNextVariants: true,
    fontSize: 16,
    htmlFontSize: 10,
    fontFamily: styles.font.family.main
  },
  palette: {
    action: {
      hover: styles.color.xxxLightGrey,
      active: styles.color.xLightGrey
    },
    text: {
      primary: styles.color.black,
      secondary: styles.color.medLightGrey
    },
    primary: {
      main: styles.color.purple,
      contrastText: styles.color.white,
      dark: styles.color.medPurple
    },
    secondary: {
      main: styles.color.purple
    }
  },
  overrides: {
    MuiMenuItem: {
      root: {
        fontWeight: Number(styles.font.weight.medium)
      }
    },
    MuiFab: {
      root: {
        width: 48,
        height: 48
      },
      label: {
        fontSize: '0.5rem'
      }
    },
    MuiSnackbarContent: {
      root: {
        boxShadow: styles.shadow.default,
        borderRadius: styles.border.radius,
        backgroundColor: styles.color.black,
        fontFamily: styles.font.family.main,
        fontSize: styles.font.size.p1
      }
    }
  }
});

export default materialTheme;
