import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { defineMessages, injectIntl } from 'react-intl';
import { css } from '@emotion/core';
import styles from '../../../../styles';
import Icon from '../../../Icon';

const messages = defineMessages({
  placeholder: {
    id: 'search.placeholder',
    defaultMessage: 'Search',
  },
});

class Search extends Component {
  state = { isOpen: false, value: '' };

  textInput = React.createRef();

  render() {
    const { id, intl } = this.props;
    const { isOpen, value } = this.state;
    return (
      <form css={style.form(isOpen)} onSubmit={this._handleSubmit}>
        <button
          css={style.icon}
          onClick={this._focusInput}
          tabIndex={-1}
          type='button'
          aria-label={intl.formatMessage(messages.placeholder)}>
          <Icon name='search' />
        </button>
        <label style={{ fontSize: 0 }} htmlFor={id}>
          {intl.formatMessage(messages.placeholder)}
        </label>
        <input
          id={id}
          css={style.input(isOpen)}
          ref={this.textInput}
          type='search'
          placeholder={intl.formatMessage(messages.placeholder)}
          value={value}
          onChange={this._handleChange}
          onFocus={this._open}
          onBlur={this._close}
        />
      </form>
    );
  }

  _handleChange = (e) => this.setState({ value: e.target.value });

  _handleSubmit = (e) => {
    e.preventDefault();
    const { onSubmit } = this.props;
    const { value } = this.state;
    onSubmit && value && onSubmit(value);
  };

  _focusInput = (e) => {
    e.preventDefault();
    this.textInput.current.focus();
    this._open();
  };

  _open = () => {
    const { onToggleSearch } = this.props;
    onToggleSearch && onToggleSearch(true);
    this.setState({ isOpen: true });
  };

  _close = () => {
    const { onToggleSearch } = this.props;
    onToggleSearch && onToggleSearch(false);
    this.setState({ isOpen: false });
  };
}

Search.propTypes = {
  id: PropTypes.string,
  onToggleSearch: PropTypes.func,
  onSubmit: PropTypes.func,
};

const style = {
  form: (isOpen) => css`
    position: relative;
    width: ${isOpen ? '100%' : '1.6rem'};
    transition: width ${styles.easing.time} ${styles.easing.main};
  `,
  icon: css`
    cursor: pointer;
    outline: none;
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    font-size: 0.5rem;
    color: ${styles.color.purple};
  `,
  input: (isOpen) => css`
    outline: none;
    width: ${isOpen ? '100%' : '1.6rem'};
    opacity: ${isOpen ? '1' : '0'};
    border-bottom: 2px solid ${styles.color.purple};
    border-color: ${isOpen ? styles.color.purple : 'transparent'};
    font-size: ${styles.font.size.p1};
    padding: ${styles.spacing.tiny};
    padding-left: ${styles.spacing.xs};
    transition: width ${styles.easing.time} ${styles.easing.main},
      border-color ${styles.easing.time} ${styles.easing.main},
      opacity ${styles.easing.time} ${styles.easing.main};
    &::placeholder {
      color: ${styles.color.medLightGrey};
    }
  `,
};

export default injectIntl(Search);
