import React from 'react';
import PropTypes from 'prop-types';
import { Global } from '@emotion/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import baseStyles from './styles/base';
import materialTheme from './styles/vendor/materialTheme';
import LocaleProvider from './components/locale/LocaleProvider';
import IconBase from './components/Icon/IconBase';

const App = ({ locale, children }) => (
  <LocaleProvider locale={locale}>
    <>
      <Global styles={baseStyles} />
      <IconBase />
      <MuiThemeProvider theme={materialTheme}>{children}</MuiThemeProvider>
    </>
  </LocaleProvider>
);

App.propTypes = {
  locale: PropTypes.string.isRequired
};

export default App;
