import React from 'react';
import { Global, css } from '@emotion/core';

export default () => (
  <>
    <symbol id='icon-close' viewBox='0 0 32 32'>
      <path d='M31.484 3.040l-2.524-2.524-12.96 12.978-12.96-12.978-2.524 2.524 12.978 12.96-12.978 12.96 2.524 2.524 12.96-12.978 12.96 12.978 2.524-2.524-12.978-12.96z' />
    </symbol>

    <Global
      styles={css`
        .icon-close {
          width: 3.2em;
          height: 3.2em;
        }
      `}
    />
  </>
);
