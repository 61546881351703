import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import { injectIntl, defineMessages } from 'react-intl';
import { css } from '@emotion/core';
import { Menu, MenuItem, withStyles } from '@material-ui/core';
import { langs } from '../../../locale/LocaleProvider';
import localizePath from '../../../../utils/localizePath';
import styles from '../../../../styles';
import Icon from '../../../Icon';
import LanguageIcon from './LanguageIcon';

const messages = defineMessages({
  languageSelect: {
    id: 'language.select',
    defaultMessage: 'Language Select',
  },
});

const showSelect = false;

class LanguageSelect extends Component {
  state = { anchorEl: null, value: this.props.initialValue };

  render() {
    const { classes, intl } = this.props;
    const { anchorEl, value } = this.state;
    const open = Boolean(anchorEl);
    return showSelect ? (
      <>
        <button
          css={style.button}
          aria-label={intl.formatMessage(messages.languageSelect)}
          aria-owns={open ? 'language-select' : undefined}
          aria-haspopup='true'
          onClick={this._handleOpen}>
          <LanguageIcon style={style.icon} locale={value} />
          {langs[value].name}
          <Icon style={style.arrow} name='arrow' />
        </button>
        <Menu
          id='language-select'
          anchorEl={anchorEl}
          open={open}
          onClose={this._handleClose}
          PaperProps={{ style: { width: 180, maxHeight: 275 } }}>
          {Object.keys(langs).map((locale) => (
            <MenuItem
              key={locale}
              className={classes.menuItem}
              selected={locale === value}
              onClick={() => this._handleChange(locale)}>
              <LanguageIcon style={style.icon} locale={locale} />
              {langs[locale].name}
            </MenuItem>
          ))}
        </Menu>
      </>
    ) : null;
  }

  _handleOpen = (e) => this.setState({ anchorEl: e.currentTarget });

  _handleChange = (locale) => {
    const { intl } = this.props;
    this.setState({ value: locale });
    this._handleClose();
    navigate(localizePath(window.location.pathname, intl.locale, locale));
  };

  _handleClose = () => this.setState({ anchorEl: null });
}

LanguageSelect.propTypes = {
  initialValue: PropTypes.string.isRequired,
};

const style = {
  button: css`
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: ${styles.font.size.p};
    padding: ${styles.spacing.tiny};
    color: ${styles.color.lightGrey};
    font-size: ${styles.font.size.p1};
    &:focus {
      outline: 1px dotted ${styles.color.xLightGrey};
    }
  `,
  icon: css`
    display: inline-block;
    width: 1.6rem;
    margin-right: ${styles.spacing.tiny};
  `,
  arrow: css`
    font-size: 0.2rem;
    margin-left: 0.4rem;
  `,
};

const styleOverrides = {
  menuItem: {
    paddingTop: '0.6rem',
    paddingBottom: '0.6rem',
    fontSize: styles.font.size.p1,
    fontWeight: styles.font.weight.normal,
  },
};

export default withStyles(styleOverrides)(injectIntl(LanguageSelect));
