import { css } from '@emotion/core';
import font from '../font';
import color from '../color';
import easing from '../easing';
import spacing from '../spacing';
import border from '../border';
import breakpoint from '../breakpoint';
import arrowIcon from '../../assets/images/ui/arrow.svg';

const postTemplate = css`
  .s-post {
    /*----------  Headings  ----------*/

    /* Autolinked headers */
    .anchor {
      line-height: 1.25;
      svg {
        vertical-align: middle;
        fill: ${color.grey};
      }
    }

    h1 {
      font-weight: ${font.weight.black};
      line-height: 1.2;
      margin: ${spacing.sm} 0 ${spacing.xs};
      font-size: 4rem;
      @media (min-width: ${breakpoint.sm}) {
        font-size: 5rem;
      }
      .anchor {
        line-height: 1;
      }
    }

    h2 {
      font-weight: ${font.weight.medium};
      line-height: 1.2;
      color: ${color.xLightGrey};
      margin: ${spacing.sm} 0 ${spacing.xs};
      font-size: 3rem;
      @media (min-width: ${breakpoint.sm}) {
        font-size: 3.2rem;
      }
      .anchor {
        line-height: 1;
      }
    }

    h3 {
      font-size: 2.4rem;
      font-weight: ${font.weight.bold};
      margin: ${spacing.sm} 0 ${spacing.xs};
      @media (min-width: ${breakpoint.lg}) {
        margin: 3.8rem 0 ${spacing.xs};
      }
    }

    h4 {
      font-size: 2.2rem;
      font-weight: ${font.weight.medium};
      margin: ${spacing.sm} 0 ${spacing.xs};
      @media (min-width: ${breakpoint.lg}) {
        margin: 3.8rem 0 ${spacing.xs};
      }
    }

    h5 {
      font-size: 2rem;
      font-weight: ${font.weight.medium};
      margin: ${spacing.sm} 0 ${spacing.xs};
      @media (min-width: ${breakpoint.lg}) {
        margin: 3.8rem 0 ${spacing.xs};
      }
    }

    h6 {
      font-size: 1.8rem;
      font-weight: ${font.weight.medium};
      margin: ${spacing.sm} 0 ${spacing.xs};
      @media (min-width: ${breakpoint.lg}) {
        margin: ${spacing.md} 0 ${spacing.xs};
      }
    }

    /*----------  Text  ----------*/

    p {
      line-height: 1.9;
      margin: ${spacing.xs} 0;
    }

    em {
      font-style: italic;
    }

    strong {
      font-weight: ${font.weight.bold};
    }

    /*----------  Links  ----------*/

    a {
      text-decoration: none;
      color: ${color.purple};
      transition: color ${easing.time} ${easing.main};
      &:hover {
        color: ${color.medPurple};
      }
      &:active {
        color: ${color.darkPurple};
      }
    }

    span {
      &[disabled] {
        cursor: not-allowed;
        color: ${color.lightPurple};
      }
    }

    /*----------  Buttons  ----------*/

    button {
      * {
        display: inline;
        margin: 0;
        line-height: 1.5;
        color: ${color.white};
        &:hover {
          color: ${color.white};
        }
      }

      cursor: pointer;
      display: block;
      margin: ${spacing.xs} 0;
      font-size: ${font.size.p1};
      line-height: 1.5;
      text-decoration: none;
      font-family: ${font.family.main};
      font-weight: ${font.weight.bold};
      color: ${color.white};
      background-color: ${color.purple};
      border: 2px solid ${color.purple};
      border-radius: ${border.radiusXl};
      padding: 0.5em 1.5em;
      transition: color ${easing.time} ${easing.main},
        background-color ${easing.time} ${easing.main}, border-color ${easing.time} ${easing.main};
      text-align: center;

      @media (min-width: ${breakpoint.sm}) {
        font-size: ${font.size.p};
      }

      &[disabled] {
        cursor: not-allowed;
        background-color: ${color.lightPurple};
        border-color: ${color.lightPurple};
      }

      &:not([disabled]) {
        &:hover {
          background-color: ${color.medPurple};
          border-color: ${color.medPurple};
        }
        &:active {
          background-color: ${color.darkPurple};
          border-color: ${color.darkPurple};
        }
      }
    }

    /*----------  Code  ----------*/

    .gatsby-highlight {
      margin: ${spacing.xs} 0;
    }

    /*----------  Separators  ----------*/

    hr {
      border: none;
      width: 100%;
      height: 1px;
      background: ${border.color.grey};
      margin: ${spacing.sm} 0;
      display: block;
    }

    /*----------  Unordered lists  ----------*/

    ul {
      list-style-type: none;
      margin: ${spacing.xs} 0;

      li {
        position: relative;
        margin: ${spacing.xxs} 0;
        padding-left: 2.8rem;

        p {
          margin: ${spacing.xxs} 0;
        }

        /* custom bullets */
        &:before {
          content: '•';
          position: absolute;
          top: -1.7rem;
          left: 0;
          font-size: 4.4rem;
        }
      }

      /* Nested ul */
      ul {
        margin: ${spacing.xxs} 0;

        li {
          /* custom bullets */
          &:before {
            content: '◦';
            top: -1.8rem;
            font-size: 4rem;
          }
        }
      }
    }

    /*----------  Ordered lists  ----------*/

    ol {
      counter-reset: section;
      list-style-type: none;
      margin: ${spacing.xs} 0;

      li {
        position: relative;
        padding-left: 3.6rem;
        margin: ${spacing.xs} 0;

        /* custom numbers */
        &:before {
          position: absolute;
          font-size: 1.2rem;
          top: 0;
          left: 0;
          counter-increment: section;
          content: counter(section);
          font-weight: ${font.weight.medium};
          background-color: ${color.xLightGrey};
          color: ${color.white};
          border-radius: 100%;
          width: 2.2rem;
          height: 2.2rem;
          line-height: 2.2rem;
          text-align: center;
          @media (min-width: ${breakpoint.xxl}) {
            line-height: 2.4rem;
          }
        }

        p {
          line-height: 1.5;
          display: inline;
        }

        /* Nested ol */
        ol {
          margin: 0;
        }
      }
    }

    /*----------  Footnotes  ----------*/

    .footnotes {
      ol {
        list-style-type: decimal;
        li {
          margin-left: 2rem;
          padding-left: ${spacing.tiny};
          p {
            display: block;
            margin: ${spacing.tiny} 0;
          }
          &:before {
            display: none;
          }
        }
      }
    }

    sup {
      vertical-align: super;
      font-size: smaller;
    }

    /*----------  Tables  ----------*/

    table {
      display: block;
      width: 100%;
      overflow: auto;
    }

    tr,
    th,
    td {
      border: 1px solid ${color.xxLightGrey};
    }

    th,
    td {
      padding: 0.6rem 1.3rem;
    }

    tr:nth-of-type(2n) {
      background-color: ${color.offWhite};
    }

    th {
      font-weight: ${font.weight.bold};
    }

    /*----------  Images  ----------*/

    img {
      max-width: 100%;
    }

    /*----------  Videos  ----------*/

    iframe {
      width: 100%;
    }

    .video {
      position: relative;
      padding-bottom: 56.25%; /* 16:9 */
      height: 0;
    }

    .video iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    /*----------  Details  ----------*/

    details {
      summary {
        font-size: 2rem;
        font-weight: ${font.weight.medium};
        cursor: pointer;
        display: inline-block;
        transition: color ${easing.time} ${easing.main};
        /* disable system arrow */
        &::-webkit-details-marker {
          display: none;
        }
        /* add custom arrow */
        &:before {
          content: '';
          display: inline-block;
          width: 1.6rem;
          height: 1rem;
          margin-right: 1rem;
          vertical-align: middle;
          background: url(${arrowIcon}) no-repeat center;
        }
      }
      /* open state */
      &[open] {
        summary {
          &:before {
            transform: scaleY(-1);
          }
        }
      }
    }
    /* detail list */
    dl {
      dt {
        font-weight: ${font.weight.bold};
        margin: ${spacing.tiny} 0;
      }
      dd {
        margin: ${spacing.tiny} ${spacing.xxs};
      }
    }

    /*----------  Blockquotes  ----------*/

    blockquote {
      background-color: ${color.offWhite};
      border-left: 5px solid ${color.purple};
      padding: 0.2rem 2rem;
      color: ${color.grey};
      margin: ${spacing.xs} 0;

      p {
        display: block !important;
        margin: ${spacing.tiny} 0;
        line-height: 1.8;
      }
    }

    .warning {
      blockquote {
        border-left-color: ${color.yellow};
      }
    }

    .danger {
      blockquote {
        border-left-color: ${color.darkRed};
      }
    }

    .success {
      blockquote {
        border-left-color: ${color.medGreen};
      }
    }

    /*----------  Callouts  ----------*/

    .callout {
      border-radius: ${border.radius};
      background-color: ${color.offWhite};
      padding: 0.5rem 1.5rem;
      margin: ${spacing.xs} 0;
      p {
        color: ${color.grey};
        font-size: ${font.size.p1};
        margin: 1rem 0;
      }
      strong {
        font-size: 1.6rem;
        display: block;
      }
    }
  }
`;

export default postTemplate;
